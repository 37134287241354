const environment = {
  name: process.env.REACT_APP_ENV,

  authUrl: process.env.REACT_APP_API_AUTH_URL,
  originacionUrl: process.env.REACT_APP_API_ORIGINACION_URL,
  catalogosUrl: process.env.REACT_APP_API_CATALOGOS_URL,
  creditRiskUrl: process.env.REACT_APP_API_CREDITRISK_URL,
  segurosUrl: process.env.REACT_APP_API_SEGUROS_URL,
  presignerUrl: process.env.REACT_APP_API_PRESIGNER_URL,
  vehiclesUrl: process.env.REACT_APP_API_VEHICLES_URL,
  yoloUrl: process.env.REACT_APP_YOLO_URL,

  isWIP: JSON.parse(process.env.REACT_APP_WIP),

  aplitudeAPIkey: process.env.REACT_APP_AMPLITUDE_API_KEY,

  launchDarkly: {
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDKKEY,
  },

  terminosYCondiciones: {
    appId: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_TERMINOS_Y_CONDICIONES_VERSION,
    fecha: process.env.REACT_APP_TERMINOS_Y_CONDICIONES_FECHA,
    productor: {
      version: process.env.REACT_APP_TERMINOS_Y_CONDICIONES_PRODUCTOR_VERSION,
      fecha: process.env.REACT_APP_TERMINOS_Y_CONDICIONES_PRODUCTOR_FECHA,
    },
  },

  awsLogs: {
    apiKey: process.env.REACT_APP_AWS_LOGS_API_KEY,
    apiSecret: process.env.REACT_APP_AWS_LOGS_API_SECRET,
    logGroup: process.env.REACT_APP_AWS_LOG_GROUP,
  },

  aws: {
    cognitoUserPoolWebClientId:
      process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },

  awsS3: {
    documentosBucket: process.env.REACT_APP_DOCUMENTOS_BUCKET,
    accessKeyId: process.env.REACT_APP_FILES_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_FILES_SECRET,
  },

  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  },
  oneSignal: {
    appId: process.env.REACT_APP_ONESIGNAL_ID,
  },
};

export default environment;
