import React from "react";
import ReactDOM from "react-dom";
import AppContainer from "./componentes/app/AppContainer";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import localRepository from "./localRepository";
import environment from "./environment";
import takeOverConsole from "./libs/takeOverConsole";
import "./libs/string.extension";

import { initAnalytics } from "./libs/amplitudeHelper";

import "assets/cleanup.css";
import "assets/react-bnb-gallery.css";

import { Auth0Provider } from "./componentes/Auth0/AuthContext";
import localizeMoment from "libs/localizeMoment";

takeOverConsole();
localizeMoment();
const store = configureStore({}); // Optional pass in an initialState

initAnalytics(localRepository.version.get());

console.info("RUN LANG: " + localRepository.currentLang.get());
console.info("RUN MODE: " + environment.name);

// Listen to request from Yolo app for clearing local storage.
window.addEventListener("message", (event) => {
  console.dir(event);
  // Verify the message cames from Yolo
  if (event.origin !== environment.yoloUrl) return;
  if (event.data.action === "clearLocalStorage") localStorage.clear();
});

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider>
      <AppContainer />
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);
